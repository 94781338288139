import { BASE_URL, ENDPOINT_URL } from "../../config/urlConfig.js";
import { ApiService } from "../../config/apiServices.js";

// Add dipartment
export const keygenrerationApi = (data) => {
  const METHOD_URL = BASE_URL + ENDPOINT_URL?.keygenreration.create;
  return ApiService.post(METHOD_URL, data);
};

export const ElementsSidebarApi = (data) => {
  const METHOD_URL = BASE_URL + ENDPOINT_URL?.elements.left_side_bar;
  return ApiService.post(METHOD_URL, data);
};

export const getGenerationkeysApi = (data) => {
  const METHOD_URL = BASE_URL + ENDPOINT_URL?.keygenreration.getGenerationkeys;
  return ApiService.post(METHOD_URL, data);
};


export const sentemailApi = (data) => {
  const METHOD_URL = BASE_URL + ENDPOINT_URL?.keygenreration.sent;
  return ApiService.post(METHOD_URL, data);
};
