import { BASE_URL, ENDPOINT_URL } from "../../config/urlConfig";
import { ApiService } from "../../config/apiServices";

export const GlobalSearchApi = (data) => {
  const METHOD_URL = BASE_URL + ENDPOINT_URL?.global_search?.main_search;
  return ApiService.post(METHOD_URL, data);
};

// Elements Files and Folder API
export const ElementsFolderFileApi = (data) => {
  const METHOD_URL = BASE_URL + ENDPOINT_URL?.elements?.folder_files;
  return ApiService.post(METHOD_URL, data);
};

// Elements Category API
export const ElementsCategoryApi = (data) => {
  const METHOD_URL = BASE_URL + ENDPOINT_URL?.elements?.folder_files;
  return ApiService.post(METHOD_URL, data);
};
// Elements Document API
export const ElementsFolderDocumentApi = (data) => {
  const METHOD_URL = BASE_URL + ENDPOINT_URL?.elements?.document_details;
  return ApiService.post(METHOD_URL, data);
};

//SideBar_API_
export const ElementsSidebarApi = () => {
  const METHOD_URL = BASE_URL + ENDPOINT_URL?.elements?.left_side_bar;
  return ApiService.post(METHOD_URL);
};

export const CategoryApi = (data) => {
  const METHOD_URL = BASE_URL + ENDPOINT_URL?.elements?.Category;
  return ApiService.post(METHOD_URL, data);
};
