import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  ClickAwayListener,
  Divider,
  Avatar,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import LogOutModal from "./login/LogoutModal";
import layout from "../assets/svg/SideBar/layout.svg";
import keyIcon from "../assets/svg/SideBar/key.svg";
import logouticon from "../assets/svg/SideBar/log-out.svg";

const drawerWidth = 264;
const collapsedWidth = 64;

const ListItemStyled = styled(ListItem)(({ theme, isActive }) => ({
  marginBottom: theme.spacing(1.4),
  paddingLeft: theme.spacing(2),
  height: "43px",
  color: isActive ? "#3B82F6" : "#475569",
  backgroundColor: isActive ? "#F2F4FE" : "transparent",
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: "#F2F4FE",
    color: "#3B82F6",
    borderRadius: "10px",
    "& .MuiListItemIcon-root svg": {
      fill: "#3B82F6",
    },
    "& .MuiListItemIcon-root img, & .arrow-hover": {
      filter:
        "invert(32%) sepia(100%) saturate(3345%) hue-rotate(211deg) brightness(96%) contrast(101%)",
    },
  },
  "& .MuiListItemIcon-root svg": {
    fill: isActive ? "#3B82F6" : "#475569",
  },
  "& .MuiListItemIcon-root img": {
    filter: isActive
      ? "invert(32%) sepia(100%) saturate(3345%) hue-rotate(211deg) brightness(96%) contrast(101%)"
      : "invert(0%) sepia(0%) saturate(100%) hue-rotate(0deg) brightness(100%) contrast(100%)",
  },
}));

const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => ({
  minWidth: "35px",
}));

const ListItemTextStyled = styled(ListItemText)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  fontFamily: "var(--fontfamilysans)",
  fontSize: "var(--fontsizesm)",
  fontWeight: 500,
  lineHeight: "var(--fontline-height5)",
  letterSpacing: "var(--fontletter-spacingnormal)",
  textAlign: "left",
  fontVariationSettings: "'slnt' 0",
}));

const SideBar = ({ setSidebarOpen, sidebarOpen }) => {
  const navigate = useNavigate();
  const [activeMenuItem, setActiveMenuItem] = useState("Home");
  const [isLogOutModalOpen, setIsLogOutModalOpen] = useState(false);
  const drawerData = [
    {
      ContentName: "Enterprises",
      icon: layout,
      path: "enterprises",
    },
    {
      ContentName: "Generated Key",
      icon: keyIcon,
      path: "generatekeys",
    },
  ];

  const [loading, setLoading] = useState(false); // Loading state

  const handleLogoutClick = () => {
    setIsLogOutModalOpen(true); // Open the logout modal
  };

  const handleProfileClick = () => {
    navigate("/profilepage");
  };

  const handleCloseModal = () => {
    setIsLogOutModalOpen(false); // Close the logout modal
  };

  const handleClickAway = () => {
    setSidebarOpen(false);
  };
  const handleSidebarMouseEnter = () => {
    setSidebarOpen(true);
  };
  const handleSidebarMouseLeave = () => {
    setSidebarOpen(false);
  };

  const renderListItemText = (text) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <ListItemTextStyled primary={text} />
    </Box>
  );

  const handleMenuItemClick = (item) => {
    setActiveMenuItem(item?.ContentName);
  };

  const renderMenuItems = () =>
    drawerData?.map((item, index) => (
      <ListItemStyled
        key={index}
        button
        component={Link}
        to={item.path}
        isActive={activeMenuItem === item?.ContentName}
        onClick={(e) => {
          if (item) {
            setActiveMenuItem(item); // Set active by for dynamic items
            handleMenuItemClick(item); // Trigger module-specific logic
          }
          if (item.onClick) {
            item.onClick(); // Trigger onClick if available
          } else {
            // handleClickAway(); // Close sidebar if there's no onClick handler
          }
        }}
      >
        <ListItemIconStyled>
          <img src={item.icon} alt={item?.ContentName} />
        </ListItemIconStyled>
        {sidebarOpen && renderListItemText(item?.ContentName)}
      </ListItemStyled>
    ));

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div style={{ display: "flex", position: "relative" }}>
          <div
            // onClick={handleSidebarClick}
            onMouseEnter={handleSidebarMouseEnter}
            onMouseLeave={handleSidebarMouseLeave}
            style={{ cursor: "pointer" }}
          >
            <Drawer
              variant="permanent"
              style={{
                width: sidebarOpen ? drawerWidth : collapsedWidth,
                flexShrink: 0,
                overflow: "hidden",
                top: "72px",
                height: "calc(100vh - 72px)",
              }}
              PaperProps={{
                style: {
                  width: sidebarOpen ? drawerWidth : collapsedWidth,
                  overflow: "hidden",
                  top: "72px",
                  height: "calc(100vh - 72px)",
                  boxShadow: "2px 0 5px rgba(0, 0, 0, 0.2)",
                  backgroundColor: "white",

                  color: "#475569",
                  paddingTop: "1rem",
                },
              }}
            >
              <Box style={{ overflow: "hidden", margin: "0.3rem" }}>
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "1rem",
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </Box>
                ) : (
                  <List>{renderMenuItems(drawerData)}</List>
                )}
              </Box>
              <div
                style={{
                  overflow: "hidden",
                  margin: "0.3rem",
                  position: "fixed",
                  bottom: "0%",
                  left: "0%",
                }}
              >
                <List>
                  {/* <ListItemStyled onClick={handleLogoutClick}>
                    <img alt="" style={{ marginLeft: "-10px" }} />

                    {sidebarOpen && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <IconButton onClick={handleLogoutClick}>
                          <img src={logouticon} alt="logouticon" />
                        </IconButton>
                      </Box>
                    )}
                  </ListItemStyled> */}
                  <Divider sx={{ backgroundColor: "#E2E8F0" }} />
                  <ListItemStyled
                    sx={{ marginTop: "1rem" }}
                    onClick={handleProfileClick}
                  >
                    <ListItemIconStyled>
                      <Avatar
                        alt="Mano"
                        src="/static/images/avatar/1.jpg"
                        style={{
                          height: "40px",
                          width: "40px",
                          marginLeft: "-8px",
                        }}
                      />
                    </ListItemIconStyled>
                    {sidebarOpen && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          "&:hover .MuiTypography-root.MuiTypography-body2.MuiListItemText-secondary":
                            {
                              color: "#3D54CD",
                            },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            "&:hover .MuiTypography-root.MuiTypography-body2.MuiListItemText-secondary":
                              {
                                color: "#3B82F6",
                              },
                          }}
                        >
                          <ListItemTextStyled
                            primary={"Mano Karan"}
                            secondary="User ID: 1234"
                            sx={{
                              "& .MuiTypography-root.MuiTypography-body2.MuiListItemText-secondary":
                                {
                                  color: "#475569",
                                },
                            }}
                          />
                        </Box>
                        <div onClick={handleLogoutClick}>
                          <img
                            src={logouticon}
                            alt="logouticon"
                            style={{ marginLeft: "1rem" }}
                          />
                        </div>
                      </Box>
                    )}
                  </ListItemStyled>
                </List>
              </div>
            </Drawer>
          </div>
          {isLogOutModalOpen && (
            <LogOutModal open={isLogOutModalOpen} onClose={handleCloseModal} />
          )}
        </div>
      </ClickAwayListener>
    </>
  );
};

export default SideBar;
