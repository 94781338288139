import React, { useState, useEffect, useRef } from "react";
import {
  AppBar,
  Toolbar,
  InputBase,
  alpha,
  styled,
  Box,
  Typography,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import bell from "../../assets/svg/navbar/bell-icon.svg";
import bookMark from "../../assets/svg/navbar/BookMark-icon.svg";
import ActiveBookMark from "../../assets/svg/navbar/ActiveBookMark.svg";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import "./NavBar.css";
import SearchModal from "./SearchModal";
import { useDispatch, useSelector } from "react-redux";
import { GetGlobalSearch } from "../../store/search/action";
import { GetElementsSidebar } from "../../store/elements/action";
import debounce from "lodash/debounce";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "rgba(255, 255, 255, 0.2)",
  width: "100%",
  maxWidth: "440px",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.05),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, `calc(1em + ${theme.spacing(4)})`),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    "&::placeholder": {
      color: "white",
    },
    color: "white",
  },
}));

const NavBar = ({ sidebarOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchResults = useSelector((state) => state?.search?.search);
  const [searchOpen, setSearchOpen] = useState(false);
  const [bookmarkActive, setBookmarkActive] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [moduleNames, setModuleNames] = useState([]);
  const searchRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    dispatch(GetElementsSidebar())
      .then((response) => {
        const data = response?.payload?.data || [];
        setModuleNames(data); // Store full data
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname === "/save-list") {
      setBookmarkActive(true);
    } else {
      setBookmarkActive(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        modalRef.current &&
        !modalRef.current.contains(event.target)
      ) {
        setSearchOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleBookmarkClick = () => {
    if (!bookmarkActive) {
      navigate("/save-list");
    }
  };

  const handleFocus = () => {
    setSearchOpen(true);
  };

  const handleSearchChange = (e) => {
    setSearchParam(e.target.value);
  };

  const debouncedSearch = useRef(
    debounce((search) => {
      const requestBody = {
        ModuleTypeIDs: [],
        SearchParam: search,
      };

      dispatch(GetGlobalSearch(requestBody));
    }, 300) // 300 ms delay
  ).current;

  useEffect(() => {
    if (searchParam !== "") {
      debouncedSearch(searchParam);
    }
  }, [searchParam, debouncedSearch]);

  return (
    <AppBar
      position="fixed"
      className="appBar"
      style={{ color: "black", backgroundColor: "#3B82F6" }}
    >
      <Toolbar className="toolbar">
        <Typography variant="h5" style={{ color: "#ffffff" }} className="logo">
          PLUTO
        </Typography>
        <Box className="center">
          <Search
            ref={searchRef}
            className="search"
            onClick={handleFocus}
            tabIndex={0}
          >
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search for Documents, SOPs, Trainings, Tests"
              inputProps={{ "aria-label": "search" }}
              onChange={handleSearchChange}
            />
          </Search>
        </Box>
        <Box className="icons">
          <img
            src={bookmarkActive ? ActiveBookMark : bookMark}
            alt="bookmark"
            onClick={handleBookmarkClick}
            style={{ cursor: "pointer" }}
          />
          <img src={bell} alt="bell" />
        </Box>
      </Toolbar>
      {searchOpen && (
        <SearchModal
          ref={modalRef}
          onClose={() => setSearchOpen(false)}
          moduleNames={moduleNames}
          combinedData={searchResults?.data || {}} // Pass full data here
          searchParam={searchParam}
        />
      )}
    </AppBar>
  );
};

export default NavBar;
