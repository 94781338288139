import { createAsyncThunk } from "@reduxjs/toolkit";
import notify from "../../assets/svg/utils/toast/Toast";
import {
  AddenterpriseApi,
  deleteEnterpriseApi,
  EditenterpriseApi,
  enterpriseApi,
} from "../../services/enterprise/enterprise";

// Add Enterprise API
export const Addenterprise = createAsyncThunk(
  "Addenterprise",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await AddenterpriseApi(data);
      if (response?.status === 201) {
        notify("success", response.data.message);
        dispatch(Getentrprise()); // Fetch updated list after deletion
        return response?.data;
      } else {
        notify("error", response.data.message || "Failed to add enterprise");
        return rejectWithValue("Failed to add enterprise");
      }
    } catch (error) {
      notify("error", error?.response?.data?.message || "An error occurred");
      return rejectWithValue(error?.response?.data || "An error occurred");
    }
  }
);

export const Editenterprise = createAsyncThunk(
  "EditenterpriseApi",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await EditenterpriseApi(data);
      if (response?.status === 200) {
        notify("success", response.data.message);
        dispatch(Getentrprise()); // Fetch updated list after deletion
        return response?.data;
      } else {
        notify("error", response.data.message || "Failed to add enterprise");
        return rejectWithValue("Failed to add enterprise");
      }
    } catch (error) {
      notify("error", error?.response?.data?.message || "An error occurred");
      return rejectWithValue(error?.response?.data || "An error occurred");
    }
  }
);
// Get Enterprise API
export const Getentrprise = createAsyncThunk(
  "Getentrprise",
  async (data, { rejectWithValue }) => {
    try {
      const response = await enterpriseApi(data);
      if (response?.status === 200) {
        return response?.data?.data;
      } else {
        notify("error", response.data.message || "Failed to fetch enterprises");
        return rejectWithValue("Failed to fetch enterprises");
      }
    } catch (error) {
      notify("error", error?.response?.data?.message || "An error occurred");
      return rejectWithValue(error?.response?.data || "An error occurred");
    }
  }
);

// Delete Enterprise API
export const deleteEnterprise = createAsyncThunk(
  "deleteEnterprise",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await deleteEnterpriseApi(data);
      if (response?.status === 200) {
        notify(
          "success",
          response?.data.message || "Enterprise deleted successfully"
        );
        dispatch(Getentrprise()); // Fetch updated list after deletion
        return response?.data?.data;
      } else {
        notify("error", response.data.message || "Failed to delete enterprise");
        return rejectWithValue("Failed to delete enterprise");
      }
    } catch (error) {
      notify("error", error?.response?.data?.message || "An error occurred");
      return rejectWithValue(error?.response?.data || "An error occurred");
    }
  }
);

