// ElementsFolderFileApi
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ElementsCategoryApi,
  ElementsFolderFileApi,
  ElementsFolderDocumentApi,
  ElementsSidebarApi,
  CategoryApi,
} from "../../services/elements/Elements";
import notify from "../../assets/svg/utils/toast/Toast";
// subside name
export const GetElementsCategory = createAsyncThunk(
  "GetElementsCategory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ElementsCategoryApi(data);
      if (response?.status === 200) {
        return response?.data;
      } else {
        // notify('error', 'Something went wrong')
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//folders API
export const GetElementsFolderFile = createAsyncThunk(
  "GetElementsFolderFile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ElementsFolderFileApi(data);
      if (response?.status === 200) {
        return response?.data;
      } else {
        // notify('error', 'Something went wrong')
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//folders Document API
export const GetElementsFolderDocument = createAsyncThunk(
  "elements/GetElementsFolderDocument",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ElementsFolderDocumentApi(data);
      if (response?.status === 200) {
        return response?.data;
      } else {
        // notify('error', 'Something went wrong');
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Left-side-bar API

export const GetElementsSidebar = createAsyncThunk(
  "elements/GetElementsSidebar",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ElementsSidebarApi();
      if (response?.status === 200) {
        return response?.data;
      } else {
        // notify('error', 'Something went wrong');
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const AddCategory = createAsyncThunk(
  "elements/AddCategory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CategoryApi(data);
      if (response?.status === 201) {
        notify("success", response.data.message);
        return response?.status;
      } else {
        notify("error", response.data.message);
      }
    } catch (error) {
      notify("error", error.data.message);
      return rejectWithValue(error?.response?.message || "An error occurred");
    }
  }
);
