import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { LoginApi, AccessTokenApi } from "../services/auth/Auth";
import notify from "../assets/svg/utils/toast/Toast";
import {
  CLIENT_ID,
  CLIENT_SECRET,
  REDIRECT_URI,
  GRAND_TYPE,
  STATE,
  RESPONSE_TYPE,
} from "../config/urlConfig";

const UseLoginSubmit = () => {
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
  });

  const LoginSubmit = async (data) => {
    try {
      const payload = {
        client_id: CLIENT_ID,
        // client_secret:CLIENT_SECRET, removed for lateset API
        UserName: data?.username,
        Password: data?.password,
        redirect_uri: REDIRECT_URI,
        response_type: RESPONSE_TYPE,
        grant_type: GRAND_TYPE,
        state: STATE,
      };
      const response = await LoginApi(payload);

      if (response.data) {
        const url = new URL(response.data.link);
        const value = url.searchParams.get("code");

        try {
          const tokenResponse = await AccessTokenApi({
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            grant_type: GRAND_TYPE,
            code: value,
          });

          localStorage.setItem(
            "access_token",
            tokenResponse?.data?.access_token
          );
          localStorage.setItem("isAuthenticated", true);
          localStorage.setItem("user_id", tokenResponse?.data?.id);

          // notify("success", "Login successful! Redirecting to dashboard...");

          // Log the token and user id to ensure they are stored
          // console.log(
          //   "Access token stored:",
          //   localStorage.getItem("access_token")
          // );
          // console.log("User ID stored:", localStorage.getItem("user_id"));

          reset();
          navigate("/enterprises");
        } catch (tokenError) {
          console.error("AccessTokenApi error: ", tokenError);
           notify("error", "Failed to retrieve access token.");
        }
      } else {
        // notify("success", response.data.message);
      }
    } catch (error) {
      console.error("Login error: ", error);
      // notify("error", "Login failed.");
    }
  };

  return { register, handleSubmit, setValue, reset, errors, LoginSubmit };
};

export default UseLoginSubmit;
