import { createSlice } from "@reduxjs/toolkit";
import { GetElementsSidebar, getGenerationkeys, Keygenreation } from "./action";

const initialState = {
  data: {},
  loading: false,
  elementsSidebar: [],
  error: null,
  generationkeysList: [],
};

const keygenreration = createSlice({
  name: "Keygenreation",
  initialState,
  data: {},
  extraReducers: (builder) => {
    builder.addCase(Keygenreation.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(Keygenreation.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(Keygenreation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || "An error occurred";
    });
    builder.addCase(GetElementsSidebar.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(GetElementsSidebar.fulfilled, (state, action) => {
      state.loading = false;
      state.elementsSidebar = action.payload; // Store sidebar data separately
    });
    builder.addCase(GetElementsSidebar.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || "An error occurred";
    });
    builder.addCase(getGenerationkeys.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getGenerationkeys.fulfilled, (state, action) => {
      state.loading = false;
      state.generationkeysList = action.payload; // Store sidebar data separately
    });
    builder.addCase(getGenerationkeys.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || "An error occurred";
    });
  },
});

export default keygenreration.reducer;
