import { BASE_URL, ENDPOINT_URL } from "../../config/urlConfig";
import { UnAuthApiService } from "../../config/apiServices";


export const LoginApi = (loginData) => {
    const METHOD_URL = BASE_URL + ENDPOINT_URL?.auth?.login
    return UnAuthApiService.post(METHOD_URL, loginData);
  };

  export const AccessTokenApi = (accessTokenData) => {
    const METHOD_URL = BASE_URL + ENDPOINT_URL?.auth?.access_token;
    
    return UnAuthApiService.post(METHOD_URL, accessTokenData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  };
  
