import React from 'react';
import { Box, Button, Typography, Modal, IconButton, Grid } from '@mui/material';
import logout from '../../assets/image/LoginPage/logout.svg'

import { useNavigate } from 'react-router-dom';

const LogOutModal = ({ open, onClose }) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('access_token');
    navigate('/');
  }
  return (
    <Modal
      open={open}
      onClose={onClose}  // Use the passed `onClose` prop to close the modal
      aria-labelledby="logout-modal-title"
      aria-describedby="logout-modal-description"
    >
      <Box sx={modalStyle}>
        <IconButton
          disableRipple
          onClick={onClose}  // Call the `onClose` prop when the button is clicked
          sx={{ 
            color: '#F50057',
            backgroundColor: '#FDE8E8', 
            mb: 2,
            alignSelf: 'center',
          }}
        >
          {/* <CloseIcon sx={{ fontSize: 40 }} /> */}
          <img src={logout} alt="" />
        </IconButton>
        <Typography id="logout-modal-title" variant="h6" component="h2" align="center">
          Are you sure you want to logout?
        </Typography>
        <Typography id="logout-modal-description" sx={{ color: '#64748B' }} align="center">
          You will need to login again to access your account.
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={onClose} sx={{ width: '100%' }} style={{borderColor:"#D0D5DD",color:'#000',borderRadius:"8px"}}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="error" sx={{ width: '100%' }} style={{backgroundColor:'#B91C1C',borderRadius:"8px"}}  onClick={handleLogout} >
              Logout
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  width: '485px',
  height: '248px',
  bgcolor: 'background.paper',
  borderRadius: '12px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  padding: '24px',  // Changed to lowercase 'padding'
  transform: 'translate(-50%, -50%)',
};

export default LogOutModal;
