import React, { forwardRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SearchModal.css';
import arrowIcon from '../../assets/svg/navbar/arrow-up-right2.svg';
import icon1 from '../../assets/svg/navbar/OpenBook.svg';
import icon2 from '../../assets/svg/navbar/layer.svg';
import icon3 from '../../assets/svg/navbar/video.svg';
import icon4 from '../../assets/svg/navbar/window.svg';
import TestMcq from '../../assets/svg/navbar/edit.svg';
import { useDispatch } from 'react-redux';
import { setModuleTypeID } from '../../store/moduleid/slice';

const SearchModal = forwardRef(({ onClose, moduleNames, combinedData, searchParam }, ref) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // State to track the selected module names
  const [activeModules, setActiveModules] = useState([]);

  const handleButtonClick = (moduleName) => {
    setActiveModules(prevActiveModules => {
      // Toggle the module name in the activeModules array
      const newActiveModules = prevActiveModules.includes(moduleName)
        ? prevActiveModules.filter(name => name !== moduleName)
        : [...prevActiveModules, moduleName];
      
      // Set the module type ID if the module is active
      if (newActiveModules.includes(moduleName)) {
        const module = moduleNames.find(item => item.ModuleName === moduleName);
        if (module) {
          dispatch(setModuleTypeID(module.ModuleTypeID));
        }
      }
      
      // Special handling for "Documents"
      if (moduleName === "Documents") {
        navigate('/search-result');
        onClose();
      }
      
      return newActiveModules;
    });
  };

  const handleItemClick = (itemText) => {
    for (const [module, entries] of Object.entries(combinedData)) {
      if (!activeModules.includes(module)) continue; // Skip non-active modules

      const found = entries.find(entry => {
        let name;
        switch (module) {
          case 'SOP':
            name = entry.SOPName;
            break;
          case 'TrainingSimulation':
            name = entry.TrainingSimulationName;
            break;
          case 'TestSimulation':
            name = entry.TestSimulationName;
            break;
          case 'TestMCQ':
            name = entry.TestMCQName;
            break;
          case 'Document':
            name = entry.DocumentName;
            break;
          default:
            return false;
        }
        return name === itemText;
      });
      
      if (found) {
        // Navigate based on the ModuleName
        switch (found.ModuleName) {
          case 'SOP':
            navigate('/sops/view');
            break;
          case 'TrainingSimulation':
            navigate('/training-simulations/view');
            break;
          case 'TestSimulation':
            navigate('/test-simulations/view');
            break;
          case 'TestMCQ':
            navigate('/test-mcqs/view');
            break;
          case 'Document':
            navigate('/documents/view');
            break;
          default:
            console.log('No matching route found');
        }
        break;
      }
    }
  };

  const generateSearchItems = () => {
    const items = [];
    
    for (const [module, entries] of Object.entries(combinedData)) {
      if (activeModules.length > 0 && !activeModules.includes(module)) continue; // Skip non-active modules when activeModules is not empty

      entries.forEach(entry => {
        let name;
        let icon;
        switch (module) {
          case 'SOP':
            name = entry.SOPName;
            icon = icon2;
            break;
          case 'TrainingSimulation':
            name = entry.TrainingSimulationName;
            icon = icon3;
            break;
          case 'TestSimulation':
            name = entry.TestSimulationName;
            icon = icon4;
            break;
          case 'TestMCQ':
            name = entry.TestMCQName;
            icon = TestMcq;
            break;
          case 'Document':
            name = entry.DocumentName;
            icon = icon1;
            break;
          default:
            return;
        }

        // If there is search input, filter based on the searchParam
        if (searchParam && name.toLowerCase().includes(searchParam.toLowerCase())) {
          items.push({ text: name, icon });
        }
      });
    }
    
    return items;
  };

  const searchItems = searchParam ? generateSearchItems() : []; // Only show items if searchParam has value

  return (
    <div className="search-modal" ref={ref} tabIndex={-1}>
      <p className='search-modal-heading'>Filter by</p>
      <div className="quick-search">
        {moduleNames?.map((item, index) => (
          <button
            key={index}
            style={{
              backgroundColor: activeModules.includes(item.ModuleName) ? "#3B82F6" : "#EFF6FF",
              color: activeModules.includes(item.ModuleName) ? "#FFFFFF" : "#3B82F6",
              borderColor: activeModules.includes(item.ModuleName) ? "#3B82F6" : "#EFF6FF"
            }}
            onClick={() => handleButtonClick(item.ModuleName)}
          >
            {item.ModuleName}
          </button>
        ))}
      </div>
      <div className="search-results">
        {searchItems.length > 0 ? (
          searchItems.map((item, index) => (
            <div
              className="search-item"
              key={index}
              onClick={() => handleItemClick(item.text)}
            >
              <img src={item.icon} alt="" className="search-item-icon" /> {item.text}
              <img src={arrowIcon} alt="" className="arrow-icon" />
            </div>
          ))
        ) : (
          <p>No matching results</p> // Show this message when searchItems is empty
        )}
      </div>
    </div>
  );
});

export default SearchModal;

