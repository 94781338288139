import { createAsyncThunk } from "@reduxjs/toolkit";
import notify from "../../assets/svg/utils/toast/Toast";
import {
  ElementsSidebarApi,
  keygenrerationApi,
  getGenerationkeysApi,
  sentemailApi,
} from "../../services/keygenration/keygenration";

// Favourites API

export const Keygenreation = createAsyncThunk(
  "Keygenreation",
  async (data, { rejectWithValue }) => {
    try {
      const response = await keygenrerationApi(data);
      if (response?.status === 201) {
        // notify("success",response?.data.message)
        return response?.data;
      } else {
        return rejectWithValue("Failed to fetch favourites");
      }
    } catch (error) {
      return rejectWithValue(error?.response?.data || "An error occurred");
    }
  }
);

export const GetElementsSidebar = createAsyncThunk(
  "elements/GetElementsSidebar",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ElementsSidebarApi();
      if (response?.status === 200) {
        return response?.data;
      } else {
        // notify('error', 'Something went wrong');
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getGenerationkeys = createAsyncThunk(
  "elements/getGenerationkeys",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getGenerationkeysApi();
      if (response?.status === 200) {
        return response?.data;
      } else {
        // notify('error', 'Something went wrong');
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const Sentemail = createAsyncThunk(
  "Getentrprise",
  async (data, { rejectWithValue }) => {
    try {
      const response = await sentemailApi(data);
      if (response?.status === 200) {
        return response?.data?.data;
      } else {
        notify("error", response.data.message || "Failed to fetch enterprises");
        return rejectWithValue("Failed to fetch enterprises");
      }
    } catch (error) {
      notify("error", error?.response?.data?.message || "An error occurred");
      return rejectWithValue(error?.response?.data || "An error occurred");
    }
  }
);
