import { createSlice } from "@reduxjs/toolkit";
import { Getentrprise } from "./action";

const initialState = {
  data: {},
  loading: false,
  error: null,
  enterpriseslist: [],
};

const keygenreration = createSlice({
  name: "Keygenreation",
  initialState,
  data: {},
  extraReducers: (builder) => {
    builder.addCase(Getentrprise.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(Getentrprise.fulfilled, (state, action) => {
      state.loading = false;
      state.enterpriseslist = action.payload; // Store sidebar data separately
    });
    builder.addCase(Getentrprise.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || "An error occurred";
    });
  },
});

export default keygenreration.reducer;
