import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Typography,
  TextField,
  Modal,
  Box,
  Switch,
  CircularProgress,
} from "@mui/material";
import keyicon from "../../assets/svg/SideBar/keyicon.svg";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import "./enterprises.css";
import { Addenterprise, Editenterprise } from "../../store/enterprise/action";

const Enterprises = ({ open, OnClose, editdata }) => {
  const [formState, setFormState] = useState({
    OrganizationStructureName: "",
    OrganizationStructureDescription: "",
    OrganizationStructureEmail: "",
    OrganizationStructureToken: "",
    IsActive: true,
  });
  const [uniqueCode, setUniqueCode] = useState("");
  const [errors, setErrors] = useState({});
  // const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const validateForm = () => {
    const newErrors = {};
    if (!formState.OrganizationStructureName)
      newErrors.OrganizationStructureName = "Enterprise Name is required.";
    if (!formState.OrganizationStructureEmail)
      newErrors.OrganizationStructureEmail = "Email is required.";
    if (!formState.OrganizationStructureDescription)
      newErrors.OrganizationStructureDescription = "Description is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleClick = async () => {
    if (!validateForm()) return;
    if (editdata) {
      const payload = {
        OrganizationStructureID: editdata?.OrganizationStructureID,
        OrganizationStructureName: formState.OrganizationStructureName,
        OrganizationStructureDescription:
          formState.OrganizationStructureDescription,
        OrganizationStructureEmail: formState.OrganizationStructureEmail,
        OrganizationStructureToken: uniqueCode,
        IsActive: formState.IsActive,
      };
      setLoading(true);
      try {
        const res = await dispatch(Editenterprise(payload));
        if (res) {
          OnClose();
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      const payload = {
        OrganizationStructureName: formState.OrganizationStructureName,
        OrganizationStructureDescription:
          formState.OrganizationStructureDescription,
        OrganizationStructureEmail: formState.OrganizationStructureEmail,
        OrganizationStructureToken: uniqueCode,
        IsActive: formState.IsActive,
      };
      setLoading(true);
      try {
        const res = await dispatch(Addenterprise(payload));
        if (res) {
          OnClose();
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleGenerate = () => {
    if (!formState.OrganizationStructureName) {
      setUniqueCode("Enterprise Name is required to generate a code.");
      return;
    }
    const generatedCode = `${uuidv4()}`;
    setUniqueCode(generatedCode);
  };

  const handleClose = () => {
    OnClose();
    setErrors({});
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (editdata) {
      setFormState({
        OrganizationStructureName: editdata?.OrganizationStructureName,
        OrganizationStructureDescription:
          editdata?.OrganizationStructureDescription,
        OrganizationStructureEmail: editdata?.OrganizationStructureEmail,
        OrganizationStructureToken: editdata?.OrganizationStructureToken,
        IsActive: editdata?.IsActive || true,
      });
    } else {
      setFormState({
        OrganizationStructureName: "",
        OrganizationStructureDescription: "",
        OrganizationStructureEmail: "",
        OrganizationStructureToken: "",
        IsActive: true,
      });
    }
  }, [editdata]);

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            width: 600,
            bgcolor: "background.paper",
            borderRadius: 1,
            padding: 4,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
          }}
        >
          <Box display="flex" flexDirection="column" gap="24px">
            <Box display="flex" alignItems="center" gap="16px">
              <img src={keyicon} alt="logo" />
              <Box>
                <Typography variant="h6">
                  {editdata ? "Edit Enterprise" : "New Enterprise"}
                </Typography>
                <Typography variant="body2" style={{ color: "#64748B" }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box>
              <Typography>Enterprise Name</Typography>
              <TextField
                type="text"
                placeholder="Enter enterprise name..."
                name="OrganizationStructureName"
                onChange={onChange}
                value={formState.OrganizationStructureName}
                error={!!errors.OrganizationStructureName}
                helperText={errors.OrganizationStructureName}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Box>
            <Box>
              <Typography>Enterprise Description</Typography>
              <TextField
                multiline
                rows={4}
                name="OrganizationStructureDescription"
                placeholder="Enter enterprise description..."
                variant="outlined"
                fullWidth
                onChange={onChange}
                value={formState.OrganizationStructureDescription}
                error={!!errors.OrganizationStructureDescription}
                helperText={errors.OrganizationStructureDescription}
                size="small"
              />
            </Box>
            <Box>
              <Typography>Email</Typography>
              <TextField
                type="email"
                placeholder="Enter email..."
                name="OrganizationStructureEmail"
                onChange={onChange}
                value={formState.OrganizationStructureEmail}
                error={!!errors.OrganizationStructureEmail}
                helperText={errors.OrganizationStructureEmail}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Box>
            <Box>
              <Typography>Unique Code</Typography>
              <Box display="flex" alignItems="center" gap="8px">
                <TextField
                  type="text"
                  value={uniqueCode}
                  placeholder="Generated code will appear here..."
                  readOnly
                  fullWidth
                  size="small"
                />
                <Button
                  variant="contained"
                  onClick={handleGenerate}
                  size="small"
                >
                  Generate Code
                </Button>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap="16px"
            >
              <Box display="flex" alignItems="center" gap="16px">
                <Switch
                  checked={formState.IsActive}
                  onChange={() =>
                    setFormState((prev) => ({
                      ...prev,
                      IsActive: !prev.IsActive,
                    }))
                  }
                />
                <div>
                  <Typography variant="b"> Status</Typography>
                  <Typography variant="body2">Change status</Typography>
                </div>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  color={formState.IsActive ? "#15803D" : "#B91C1C"}
                  sx={{
                    bgcolor: formState.IsActive ? "#F0FDF4" : "#FEF2F2",
                    padding: "4px 12px",
                    borderRadius: "16px",
                  }}
                >
                  {formState.IsActive ? "Active" : "Inactive"}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box mt={3} display="flex" justifyContent="space-between">
              <Button
                variant="outlined"
                onClick={handleClose}
                style={{ marginRight: 8 }}
                size="small"
                fullWidth
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleClick}
                size="small"
                disabled={loading}
                fullWidth
              >
                {loading ? <CircularProgress size={24} /> : "Save"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Enterprises;
