import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Login.css";
import img1 from "../../assets/image/LoginPage/img4.jpg";
import img2 from "../../assets/image/LoginPage/img2.jpg";
import img3 from "../../assets/image/LoginPage/img3.jpg";
import user from "../../assets/image/LoginPage/User.svg";
import lock from "../../assets/image/LoginPage/lock.svg";


import UseLoginSubmit from "../../hooks/auth";

const Login = () => {
  const { register, handleSubmit, LoginSubmit } = UseLoginSubmit();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLoginSubmit = async (data) => {
    setErrorMessage(""); // Reset error message
    setFormErrors({ username: "", password: "" }); // Reset form errors
    setLoading(true);

    const payload = {
      username: data?.username,
      password: data?.password,
    };

    // Validation for email and password fields
    let isValid = true;

    if (!payload.username) {
      setFormErrors((prev) => ({
        ...prev,
        username: "Please enter your email.",
      }));
      isValid = false;
    } else if (!validateEmail(payload.username)) {
      setFormErrors((prev) => ({
        ...prev,
        username: "Please enter a valid email address.",
      }));
      isValid = false;
    }

    if (!payload.password) {
      setFormErrors((prev) => ({
        ...prev,
        password: "Please enter your password.",
      }));
      isValid = false;
    }

    if (!isValid) {
      setLoading(false);
      return;
    }

    try {
      // Simulating API response for demo
      const response = await LoginSubmit(payload);

      if (response) {
        navigate("/enterprises");
      } else {
        setErrorMessage("User not registered."); // Unregistered user message
      }
    } catch (error) {
      // console.log("Error during login", error);
      setErrorMessage("An error occurred during login.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "25px",
          marginRight: "20px",
          height: "2px",
          background:
            i === settings.currentSlide ? "white" : "rgba(255, 255, 255, 0.2)",
        }}
      ></div>
    ),
  };

  const slides = [
    {
      image: img1,
      heading: "Yorem ipsum dolor sit amet, consectetur adipiscing elit.",
      paragraph:
        "Torem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
    },
    {
      image: img2,
      heading: "Norem ipsum dolor sit amet, consectetur adipiscing elit.",
      paragraph:
        "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
    },
    {
      image: img3,
      heading: "Corem ipsum dolor sit amet, consectetur adipiscing elit.",
      paragraph:
        "Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
    },
  ];

  return (
    <div className="root">
      <div className="navbar">
        <Typography
          variant="p"
          className="login-heading"
          sx={{
            fontSize: "1.5rem",
            lineHeight: "3rem",
            padding: "0px 10px",
            letterSpacing: "0.2rem",
          }}
        >
          PLUTO
        </Typography>
      </div>
      <div className="content">
        <div className="left-pane">
          <Slider className="slider" {...settings}>
            {slides.map((slide, index) => (
              <div key={index} className="slider-item">
                <img src={slide.image} alt={`slide-${index}`} />
                <div className="slider-content">
                  <Typography
                    variant="h4"
                    component="h1"
                    className="login-heading"
                  >
                    {slide.heading}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className="login-para"
                  >
                    {slide.paragraph}
                  </Typography>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="right-pane">
          <form
            onSubmit={handleSubmit(handleLoginSubmit)}
            className="login-form"
          >
            <Typography
              variant="h4"
              component="p"
              gutterBottom
              className="rightside-heading"
            >
              PLUTO
            </Typography>

            <TextField
              className="text-field"
              variant="outlined"
              placeholder="Email"
              {...register("username")}
              // Remove helperText and error props
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={user} alt="" />
                  </InputAdornment>
                ),
                style: {
                  backgroundColor: "white",
                  borderRadius: "var(--radiuslg)",
                },
              }}
            />
            {formErrors.username && (
              <Typography
                variant="body2"
                color="error"
                sx={{ mb: 1, textAlign: "left" }}
              >
                {formErrors.username}
              </Typography>
            )}
            <TextField
              className="text-field"
              variant="outlined"
              type="password"
              placeholder="Password"
              {...register("password")}
              // Remove helperText and error props
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={lock} alt="" />
                  </InputAdornment>
                ),
                style: {
                  backgroundColor: "white",
                  borderRadius: "var(--radiuslg)",
                },
              }}
            />
            {formErrors.password && (
              <Typography
                variant="body2"
                color="error"
                sx={{ mb: 1, textAlign: "left" }}
              >
                {formErrors.password}
              </Typography>
            )}
            {errorMessage && (
              <Typography
                variant="body2"
                color="error"
                sx={{ mb: 1, textAlign: "left" }}
              >
                {errorMessage}
              </Typography>
            )}
            <Button
              className="login-button"
              variant="contained"
              color="primary"
              // endIcon={
              //   !loading && <img src={loginButton} alt="Login Button Icon" />
              // }
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress
                  size={28}
                  color="inherit"
                  style={{ color: "#fff" }}
                />
              ) : (
                "Login"
              )}
            </Button>
          </form>
          <Typography variant="body2" component="p" className="powered-by">
            Powered By PLUTO
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Login;
